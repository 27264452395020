import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import logo from "../images/saintJohnsLogo.svg"

const retinaQuery = retinaCss => css`
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    ${retinaCss}
  }
`

const ResponsiveBackgroundImage = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;

  background-size: cover;
  background-image: url("https://ucarecdn.com/6c0177d6-759c-4423-9baa-582786d3e939/-/resize/1440x/-/format/auto/");
  ${retinaQuery(
    "background-image: url('https://ucarecdn.com/6c0177d6-759c-4423-9baa-582786d3e939/-/resize/2880x/-/format/auto/-/quality/lightest/');"
  )}

  @media screen and (orientation: portrait) {
    background-image: url("https://ucarecdn.com/6c0177d6-759c-4423-9baa-582786d3e939/-/crop/1440x200/center-/format/auto/");
    ${retinaQuery(
      "background-image: url('https://ucarecdn.com/6c0177d6-759c-4423-9baa-582786d3e939/-/crop/2000x2600/center/-/format/auto/-/quality/lightest/');"
    )}
  }

  @media screen and (max-width: 1000px) {
    background-image: url("https://ucarecdn.com/6c0177d6-759c-4423-9baa-582786d3e939/-/resize/1000x/-/format/auto/");
    ${retinaQuery(
      "background-image: url('https://ucarecdn.com/6c0177d6-759c-4423-9baa-582786d3e939/-/resize/2000x/-/format/auto/-/quality/lightest/');"
    )}
    @media screen and (orientation: portrait) {
      background-image: url("https://ucarecdn.com/6c0177d6-759c-4423-9baa-582786d3e939/-/crop/1000x2000/center-/format/auto/");
      ${retinaQuery(
        "background-image: url('https://ucarecdn.com/6c0177d6-759c-4423-9baa-582786d3e939/-/crop/2000x2600/center/-/format/auto/-/quality/lightest/');"
      )}
    }
  }

  @media screen and (max-width: 800px) {
    background-image: url("https://ucarecdn.com/6c0177d6-759c-4423-9baa-582786d3e939/-/resize/800x/-/format/auto/");
    ${retinaQuery(
      "background-image: url('https://ucarecdn.com/6c0177d6-759c-4423-9baa-582786d3e939/-/resize/1600x/-/format/auto/-/quality/lightest/');"
    )}
    @media screen and (orientation: portrait) {
      background-image: url("https://ucarecdn.com/6c0177d6-759c-4423-9baa-582786d3e939/-/crop/800x1600/center-/format/auto/");
      ${retinaQuery(
        "background-image: url('https://ucarecdn.com/6c0177d6-759c-4423-9baa-582786d3e939/-/crop/1600x2600/center/-/format/auto/-/quality/lightest/');"
      )}
    }
  }

  @media screen and (max-width: 600px) {
    background-image: url("https://ucarecdn.com/6c0177d6-759c-4423-9baa-582786d3e939/-/resize/600x/-/format/auto/");
    ${retinaQuery(
      "background-image: url('https://ucarecdn.com/6c0177d6-759c-4423-9baa-582786d3e939/-/resize/1200x/-/format/auto/-/quality/lightest/');"
    )}

    @media screen and (orientation: portrait) {
      background-image: url("https://ucarecdn.com/6c0177d6-759c-4423-9baa-582786d3e939/-/crop/600x1200/center-/format/auto/");
      ${retinaQuery(
        "background-image: url('https://ucarecdn.com/6c0177d6-759c-4423-9baa-582786d3e939/-/crop/1200x2400/center/-/format/auto/-/quality/lightest/');"
      )}
    }
  }

  @media screen and (max-width: 400px) {
    background-image: url("https://ucarecdn.com/6c0177d6-759c-4423-9baa-582786d3e939/-/resize/400x/-/format/auto/");
    ${retinaQuery(
      "background-image: url('https://ucarecdn.com/6c0177d6-759c-4423-9baa-582786d3e939/-/resize/800x/-/format/auto/-/quality/lightest/');"
    )}

    @media screen and (orientation: portrait) {
      background-image: url("https://ucarecdn.com/6c0177d6-759c-4423-9baa-582786d3e939/-/crop/400x800/center-/format/auto/");
      ${retinaQuery(
        "background-image: url('https://ucarecdn.com/6c0177d6-759c-4423-9baa-582786d3e939/-/crop/800x1600/center/-/format/auto/-/quality/lightest/');"
      )}
    }
  }
`

const Image = styled.img`
  z-index: 0;
  object-fit: cover;
  height: 100vh;
  width: 100vw;
`
const Shroud = styled.div`
  z-index: 9999;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Title = styled.h1`
  font-family: ;
`

const Logo = styled.img`
  height: 10rem;
  margin-bottom: 5rem;
`

const Splash = () => (
  <>
    <ResponsiveBackgroundImage>
      <Shroud>
        {" "}
        <Logo src={logo} alt="logo" />
        <h1>New Site Coming Soon!</h1>
      </Shroud>
    </ResponsiveBackgroundImage>
  </>
)

export default Splash
